body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.hero-input {
  /* Tell the input to use all the available space */
  flex-grow: 2;
  /* And hide the input's outline, so the form looks like the outline */
  border: none;
  margin-bottom: 30px;

}

.button {
  padding: 10px;
}



.hidden {
  display: none;
}



.blur {
  filter: blur(8px);
  image-rendering: pixelated;

}

img.blur {
  image-rendering: pixelated;
}




div#checkout {

  border-radius: 30px;
  border: solid 0px #28B2D2;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background: #28B2D2;
  color: white;
  padding: 20px;
  margin: 50px 30px 0px 30px;
  display: none;
  
}


div.benefits {

  font-size: 1em;
  font-weight: 600;
  flex: content;
  line-height: 15px;
  align-items: center;

}

div.checkout-benefit-group {
 display:flexbox;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}

div.benefits ul {

  list-style-type: none;
  padding: 3px;
  margin: 0px;
  line-height: 25px;

}



div.benefits ul li:before {
  content: '✓ ';

}

a.search-button {
  border-radius: 0px 30px 30px 0px;
  background-color: #28B2D2;

}


  
a.checkout-button {
  border-radius: 30px;
  width: 50%;
  margin-bottom: 30px;
  background-color: #6163FF;

}

a.settings-button {
  border-radius: 0px;
  border-radius: 0px 30px 30px 0px;
  background-color: #28B2D2;
  padding: 15px 10px;
  cursor: pointer;

  font-size: 12px;
  font-weight: 600;
}


div.mySwiper{
  border-radius: 0%;
}

div.swiper-slide img {

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0%;
 

}

div.swiper-slide img::selection {
  border: solid 5px #fff;
}

div.footer-copyright a {
  color: #000;
}

#greeting {
	width: 100%;
	border-radius: 30px 0px 0px 30px;
	padding:20px;
	border:0;
	display:flex;
	flex-direction:row;
}

#greeting input{
	  /* ell the input to use all the available space */
	  flex-grow:2;
	 
}


input#message{
  width: 100%;
  border: none;
  user-select: none;
  pointer-events: none;
  font-weight: 600;
  background-color: transparent;
  color:white;
  text-align: center;
  text-decoration: dotted underline;

}




#formSuggestion{
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;


}

#formSuggestion input{
  border-radius: 30px 0px 0px 30px;

}

#formSuggestion button.btnSendSuggestion{
  border-radius: 0px 30px 30px 0px;
background-color: #6163FF;
color:white;

vertical-align: middle;
align-items: center;
}




